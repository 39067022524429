import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Features extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentImage: homedata.features.singleFeature.image
        };
    }

    handleFeatureClick = (imageUrl) => {
        this.setState({ currentImage: imageUrl });
        window.open(imageUrl, '_blank');
    };

    render() {

        let factsInfo = homedata.features
        let sectionHeadingInfo = factsInfo.sectionHeading
        let featureContent = factsInfo.singleFeature
        let publicUrl = process.env.PUBLIC_URL

        const featureImages = {
            "Derecho Civil y Mercantil": "/assets/img/screenshots/Civil_Mercantil.png",
            "Derecho de Familia": "/assets/img/screenshots/Familiar.png",
            "Derecho Penal": "/assets/img/screenshots/Penal.png",
            "Derecho Administrativo": "/assets/img/screenshots/Administrativo.png",
            "Expertos en Juicios de Amparo": "/assets/img/screenshots/Amparo.png"
        };

        console.log('Current Image:', this.state.currentImage); 

        return <section id="features" className="pt100 pb100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-10 offset-lg-3 offset-md-2 offset-sm-1">
                        <div className="section-title text-center mb60">
                            <h2 dangerouslySetInnerHTML={{ __html: sectionHeadingInfo.title }}></h2>
                            <hr className="lines" />
                            <p>{sectionHeadingInfo.desc}</p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">

                    <div className="col-lg-5 text-center md-m-15px-b">
                        <img className="img-fluid" src={publicUrl + featureContent.image} alt={`features`} />
                    </div>

                    <div className="col-lg-7">
                        <div className="row">
                            <div className="col-md-6">
                                {
                                    featureContent.featurePart1.map((element, i) => {
                                        return (

                                            <div key={i} className="media single-feature-item" onClick={() => this.handleFeatureClick(featureImages[element.title])} >
                                                <div className="feature-icon">
                                                    <i className={element.icon}></i>
                                                </div>
                                                <div className="media-body">
                                                    <h5>{element.title}</h5>
                                                    <p>{element.desc}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className="col-md-6">
                                {
                                    featureContent.featurePart2.map((element, i) => {
                                        return (
                                            <div key={i} className="media single-feature-item" onClick={() => this.handleFeatureClick(featureImages[element.title])} >
                                                <div className="feature-icon">
                                                    <i className={element.icon}></i>
                                                </div>
                                                <div className="media-body">
                                                    <h5>{element.title}</h5>
                                                    <p>{element.desc}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    }
}


export default Features
